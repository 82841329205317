import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import InformationGridItem, { isEmptyItem } from './InformationGridItem';

function getItems(props) {
  let items = [];

  if (!isEmptyItem(props)) {
    if (Array.isArray(props.children)) {
      for (const child of props.children) {
        items.push(getItems(child?.props));
      }
    } else {
      items.push(props.children);
    }
  }

  return items.flat(10);
}

const useStyles = makeStyles(theme => ({
  informationGrid: {
    marginBottom: props => (props.context ? 0 : theme.spacing(3)),
    '& table': {
      borderSpacing: 0,
      width: '100%'
    }
  }
}));

const InformationGridContext = React.createContext({});

export default function InformationGrid(props) {
  const classes = useStyles(props);
  const [hasItems, setHasItems] = useState(true);

  useEffect(() => {
    setHasItems(getItems(props).length > 0);
  }, [props.children]);

  return (
    <InformationGridContext.Provider
      value={{
        size: props.size,
        labelWidth: props.labelWidth,
        context: props.context
      }}
    >
      {hasItems ? (
        <>
          {props.title}
          <Box className={classes.informationGrid}>
            <table>
              <tbody>{props.children}</tbody>
            </table>
          </Box>
        </>
      ) : null}
    </InformationGridContext.Provider>
  );
}

InformationGrid.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  context: PropTypes.bool,
  labelWidth: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

InformationGrid.defaultProps = {
  size: 'medium',
  title: undefined,
  labelWidth: undefined,
  context: false
};

InformationGrid.Item = InformationGridItem;
InformationGrid.Context = InformationGridContext;
